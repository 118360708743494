<template>
	<van-cell
		class="title"
		:title="info.chapterName"
		title-class="text-base"
	>
		<template #icon>
			<span class="title_icon back-primary"></span>
		</template>
		<template #extra v-if="$route.query.isFace !== 'noFace'">
			<div :class="!isUseJSPlayPhoto ? 'sign-out-area' : ''">
				<van-button
					class="title_button back-line-gradient"
					@click="clickSignBack"
					:loading="loadingFace"
					style="background-image: linear-gradient(to right, #ffb6b8, #f72e0c);text-shadow: 1px 2px #ba5852;"
				>签&nbsp;退</van-button>
				<input v-if="!isUseJSPlayPhoto" ref="signBack" class="img-input" @change="imgChange($event)" type="file" accept="image/*" capture="user">
			</div>
		</template>
	</van-cell>
</template>

<script>
export default {
	name: 'studyTitle',
	props: ['info', 'loadingFace'],
	computed: {
		isUseJSPlayPhoto() {
			if (this.$store.state.cache.screen.wx) return true
			if (this.$route.query.state === 'js') return false
			// if (sessionStorage.getItem('anhuihefei') === '1') {
			//	return false
			// }
			if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
			return true
		}
	},
	methods: {
		clickSignBack () {
			// 设置了记录签到后学习视频的个数，则检查
			if (localStorage.getItem('videoPlayNums')) {
				let times = localStorage.getItem('videoPlayNums');
				if (times === '0') {
					this.$dialog.confirm({
						title: '温馨提示',
						message: '此时签退不计学时，您需要完整学习该视频至播放结束。确定签退吗？',
					}).then(() => {
						this.handleSignOut();
					}).catch(() => {
						console.log('继续学习');
					});
				} else {
					this.handleSignOut();
				}
			} else {
				// this.$dialog.alert({message: "请您重新签到进入学习"});
				this.handleSignOut();
			}
		},
		handleSignOut() {
			const _this = this;
			this.$api.checkSignOut(this.$route.query.signId).then(res => {
				if (res.code === 0) {
					if (res.data.canBeSignOut == 1) {
						if (_this.loadingFace) return
						if (!_this.isUseJSPlayPhoto) {
							_this.$refs.signBack.click()
						} else {
							_this.$emit('handleFace')
						}
					} else {
						_this.$utils.dialog.alert(res.data.msg)
					}
				}
			})
		},
		imgChange (event) {
			if (this.loadingFace) return
			this.$emit('imgChange', event)
		}
	}
}
</script>

<style lang="less" scoped>
.sign-out-area {
	position: relative;

	.img-input {
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		opacity: 0;
		height: 100%;
	}
}
</style>
