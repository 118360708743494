<template>
	<div class="sidebar back-white">
		<div class="item" v-for="(item, index) in list" :key="item.basePkId" @click="clickToggleChapter(item)">
			<img v-if="islock(item, index)" width="15px" src="@/assets/icon/icon-lock.png" alt="">
			<img v-else-if="appno == 3" width="15px" src="@/assets/icon/icon-unlock3.png" alt="">
			<img v-else-if="appno == 2" width="15px" src="@/assets/icon/icon-unlock2.png" alt="">
			<img v-else width="15px" src="@/assets/icon/icon-unlock.png" alt="">
			<span :class="{ 'color-primary': cur.basePkId === item.basePkId }">{{ item.chapterName | chapterName }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'studySidebar',
	props: ['list', 'cur', 'act', 'actVideo'],
	filters: {
		chapterName (val) {
			if (!val) return ''
			return val.substr(0, 3)
		}
	},
	computed: {
		appno () {
			return this.$store.state.app.appno
		}
	},
	methods: {
		// 是否上锁
		islock (item, index) {
			// const curIndex = this.list.findIndex(val => val.basePkId === this.cur.basePkId)
			const curIndex = this.list.findIndex(val => val.basePkId === this.act.basePkId)
			return index > curIndex ? true : false
		},
		clickToggleChapter (item) {
			let state = true
			for (let i = 0; i < item.children.length; i ++) {
				for (let j = 0; j < item.children[i].children.length; j ++) {
					const val = item.children[i].children[j]
					if (val.watchedFlag != 1 && item !== this.act) {
						if (val.basePkId === this.actVideo.basePkId) {
							state = true
							break
						} else {
							state = false
							break
						}
					}
				}
			}
			if (state) this.$emit ('clickToggleChapter', item)
		}
	}
}
</script>

<style scoped>

</style>
