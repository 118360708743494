<template>
	<div class="list">
		<div class="item back-white" v-for="(item, index) in chapter.children" :key="item.basePkId">
			<div class="title" v-if="index === 0">
				<img v-if="appno==3" src="@/assets/icon/study-title3.png" alt="" width="15px" height="15px">
				<img v-else-if="appno==2" src="@/assets/icon/study-title2.png" alt="" width="15px" height="15px">
				<img v-else src="@/assets/icon/study-title.png" alt="" width="15px" height="15px">
				<span class="color-primary">{{ chapter.chapterName | chapterName }}</span>
				<img v-if="appno==3" src="@/assets/icon/study-title3.png" alt="" width="15px" height="15px" style="transform: rotate(180deg);">
				<img v-else-if="appno==2" src="@/assets/icon/study-title2.png" alt="" width="15px" height="15px" style="transform: rotate(180deg);">
				<img v-else src="@/assets/icon/study-title.png" alt="" width="15px" height="15px" style="transform: rotate(180deg);">
			</div>
			<h2 class="second_title text-base">{{ item.chapterName }}</h2>
			<div class="section_list" :class="{ 'limit_height': showAllID !== item.basePkId }">
				<div
					class="section_item"
					v-for="val in item.children"
					:key="val.basePkId"
					@click="clickVideo(val)"
				>
					<div class="info" :class="{ disabled: val.watchedFlag === '0' && mustVideo.basePkId !== val.basePkId }" :style="`background-image: url(`+backGroundUrl+`)`" >
						<span v-if="val.watchedFlag === '0'" class="info_status back-disabled color-white">未学</span>
						<span v-else-if="val.watchedFlag === '1'" class="info_status back-white color-primary">已学</span>
						<span class="info_name back-white color-primary">{{ val.chapterName | videoName }}</span>
						<span class="info_duration color-white">{{ val.video.videoDuration | filterTime }}</span>
						<div class="info_play" v-if="mustVideo.basePkId === val.basePkId || current.basePkId === val.basePkId">
							<span v-if="isPlayState && current.basePkId === val.basePkId" class="playing"></span>
							<van-icon v-else size="32" class="play_icon color-white" name="play-circle" />
						</div>
					</div>
					<p class="item_title">{{ val.chapterName }}</p>
				</div>
				<div class="section_button color-primary back-white" v-if="item.children.length > 4" @click="showAllID = showAllID === item.basePkId ? '' : item.basePkId">
					<span>{{ showAllID === item.basePkId ? '收起全部' : '查看全部' }}</span>
				</div>
			</div>
			<div class="item_exam" v-if="item.type==4 && appno!=3">
				<img src="@/assets/chapter-test-on-st.png" alt="">
			</div>
			<div class="item_exam" v-if="item.type==4 && appno==3">
				<img src="@/assets/chapter-test-on-aq.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'studyList',
	props: ['chapter', 'current', 'mustVideo', 'isPlayState', 'videoList'],
	filters: {
		chapterName (val) {
			if (!val) return ''
			return val.substr(4)
		},
		videoName (val) {
			return val.substr(val.indexOf('.')+1)
		},
		filterTime (val) {
			return Math.floor(Number(val) / 60) + ':' + (Number(val) % 60)
		}
	},
	data: () => ({
		showAllID: '',
		backGroundUrl:'https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/d3f5ff377f40d1efde1c67769bf6874.jpg',
	}),
	watch: {
		mustVideo (newVal) {
			if (newVal.chapterIndex > 4) {
				this.showAllID = newVal.parentPkId
			}
		}
	},
	computed: {
		appno () {
			return this.$store.state.app.appno
		}
	},
	created() {
		this.chageBackGround();
	},
	methods: {
		getCurVideoState (id) {
			// videoList
			const item = this.videoList.find(item => item.basePkId === id)
			return item.waitForTrailers
		},
		clickVideo (val) {
			if (this.current.basePkId !== val.basePkId) {
				this.$emit('clickVideo', val)
			}
		},
		chageBackGround(){
				switch(this.$store.state.user.categoryid){
					case 102:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/a4dff1ddf6b0907fff24a754ccb2851.jpg"
					break
					case 104:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/38ae5b263439aba6413c62acc3d1179.jpg"
					break
					case 105:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/580f0691cfb834c7a024546b4fc037f.jpg"
					break
					case 123:
					case 124:
					case 125:
					case 152:
					case 153:
					case 160:
					case 161:
					case 162:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/1b075d1eb701c92ff222d14b697274d.jpg"
					break
					default:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/d3f5ff377f40d1efde1c67769bf6874.jpg"
					break
				}
		},
	}
}
</script>

<style scoped>

</style>
